
/** @jsxImportSource @emotion/react */
const HomeIcon = () => {

    return <svg width="65" height="64" viewBox="0 0 65 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M40.959 56V34.6667C40.959 33.9594 40.678 33.2811 40.1779 32.781C39.6778 32.281 38.9996 32 38.2923 32H27.6257C26.9184 32 26.2401 32.281 25.74 32.781C25.2399 33.2811 24.959 33.9594 24.959 34.6667V56" stroke-width="5.33333" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8.95898 26.6667C8.9588 25.8908 9.12787 25.1243 9.45442 24.4206C9.78096 23.7168 10.2571 23.0928 10.8497 22.592L29.5163 6.59466C30.4789 5.78109 31.6986 5.33472 32.959 5.33472C34.2194 5.33472 35.439 5.78109 36.4017 6.59466L55.0683 22.592C55.6609 23.0928 56.137 23.7168 56.4636 24.4206C56.7901 25.1243 56.9592 25.8908 56.959 26.6667V50.6667C56.959 52.0812 56.3971 53.4377 55.3969 54.4379C54.3967 55.4381 53.0401 56 51.6257 56H14.2923C12.8778 56 11.5213 55.4381 10.5211 54.4379C9.52089 53.4377 8.95898 52.0812 8.95898 50.6667V26.6667Z" stroke-width="5.33333" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    
}

export default HomeIcon



