/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { medium, small } from "../Global.css";




export const EarningsStyle = css`
    
    text-align:left;
    margin-top: 15px;
    justify-content: space-around;
    display: flex;
    flex-wrap: wrap;

    div{
        max-width: 40%;
        span{
            font-family: "Inter";
            font-size: 14px;
        }
    }
`

export const SunIconStyle = css`
    width: 20px;
    /* fill: rgba(255, 229, 0, 0.8); */
    fill: rgba(249,249,249,0.6)
`

export const EnergyMeterStyle = css`
    width: 22px;
    /* fill: rgba(255, 229, 0, 0.8); */
    fill: rgba(249,249,249,0.6)
`

export const BatteryIconStyle = css`
    fill: transparent;
    height: 22px;
    width: 24px;
    stroke: rgba(249,249,249,0.6)
`


export const ProductionBoxStyles = css`
    margin-top: 30px;
    display: flex;
    flex-wrap:wrap;
    padding: 0 0;
    ${medium}{
        padding: 0 5%;
    }
    margin-bottom: 20px;
    > div{
        margin-bottom: 20px;

        &.no-padding{
            margin-bottom: 0;
        }

        .react-tooltip {
            max-width: 80vw;
            line-height: 1.5;
        }
        h4{
            display: flex;
            align-items:center;
            justify-content: center;
            gap: 5px;
            text-align:center;;
            font-size: 18px;
            margin-bottom: 15px;
            ${medium}{
                justify-content: unset;
            }
            .hideInMobile{
                ${small}{
                    display: none;
                }
            }
            &[data-tooltip-id]{
                cursor:help;
            }
            
            svg{
                flex-shrink: 0;
                width: 16px;
                height: 16px;
                fill: rgba(249,249,249,0.5);
                
            }

            &:hover{
                svg{
                    fill: rgba(249,249,249,1);
                }
            }
        }
        p{
            font-family: "Inter";
            font-weight: 200;
            text-align:center;
            font-size: 20px;
            ${medium}{
                font-size: 22px;
                text-align:left;
                display: inline;
            }
            transition: all .1s;
            &.refetching{
                transform:scale(.99);
                color: rgba(255,255,255,0.8);
            }
            &.small{
                font-size: 18px;
            }
            > span{
                position: relative;
            }
        }
        > span{
            font-size: 12px;
            color: rgba(249,249,249,0.4);
            font-weight: 200;
            display: block;
            margin-top: 3px;
        }
        width: 50%;
    }

`

export const ScopesButtonGroupStyle = css`
    border: 1px solid #202838;
    border-radius: 3px;
    white-space: nowrap;
    width: fit-content;
    margin: 0 auto 20px auto;
    background: rgb(55, 65, 81);

    button{
        padding: 8px 10px;
        width: 80px;
        margin-right:1px;
        border: none;
        color: #fff;
        position: relative;
        background: transparent;
        user-select: none;

        ${medium}{
            width: 99px;
        }

        &:hover{
            background:rgba(255, 229, 0, 0.4);
        }

        &.active{
            text-decoration: underline;
            background:rgba(255, 229, 0, 0.6);
            text-shadow: #000 0px 0 5px;
        }


        &:after{
            content: "";
            display: block;
            width: 1px;
            background: black;
            position: absolute;
            top: 3px;
            bottom: 3px;
            right: -1px;
        }

        &:first-of-type{
            border-radius: 3px 0 0 3px;
        }
        &:last-child{
            border-radius: 0 3px 3px 0;
            margin-right 0px;
            &:after{
                display: none;
            }
        }
    }

`


export const ContentFooterLinks = css`
    font-family: "Inter";
    display: flex;
    
    margin-top: 15px;
    margin-bottom: 15px;
    row-gap: 10px;
    flex-wrap: wrap;
    row-gap: 5px;
    column-gap: 3px;
    justify-content: space-around;
    font-size: 13px;
    ${medium}{
        column-gap: 5px;
        row-gap: 10px;
        font-size: 14px;
        justify-content:space-between;
    }
    

    li{        
        display: flex;
        gap: 3px;
        min-width: 35%;
        overflow: hidden;
        white-space: nowrap;
        color: rgba(249, 249, 249, 0.8);
        font-family: "Inter";
        font-weight: 200;
        span{
            font-weight: 300;

            &.devicepk {
                body.deviceCopied &{
                    color: rgba(255,255,255,1);
                    transform: scale(1.025);
                }
            }
            &.wallet {
                body.walletCopied &{
                    color: rgba(255,255,255,1);
                    transform: scale(1.025);
                }
            }
            /* hacks */
            &.derid1 {
                body.deridCopied1 &{
                    color: rgba(255,255,255,1);
                    transform: scale(1.025);
                }
            }
            &.derid2 {
                body.deridCopied2 &{
                    color: rgba(255,255,255,1);
                    transform: scale(1.025);
                }
            }
            &.derid2 {
                body.deridCopied3 &{
                    color: rgba(255,255,255,1);
                    transform: scale(1.025);
                }
            }
            &.derid3 {
                body.deridCopied4 &{
                    color: rgba(255,255,255,1);
                    transform: scale(1.025);
                }
            }
        }
        button{
                position: relative;
                top: -2px;
            }
    }

`

export const BetaChangeStyle = css`
    color: #90ee90;
    font-size: 11px;
    position: absolute;
    left: 1%;
    white-space: nowrap;
    top: calc(100% + 3px);
    z-index: 1;
    cursor:help;
    min-width: 50px;
    span{
        display: flex;
        gap: 3px;
        margin-bottom: 2px;
        > span{
            color: rgba(249, 249, 249, 0.7);
        }
    }
`

export const NoDerInfoStyle = css`
    text-align: center;
    font-weight: 200;
    margin: 5px 10px 15px 10px;
    span{
        margin-top: 10px;
        padding: 0px 60px;
        display: block;
        font-size: 12px;
    }

`