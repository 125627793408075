/** @jsxImportSource @emotion/react */
import { Link, useParams } from 'react-router-dom';
import { ExtendedWrapperStyle, ExtendedHeadingStyle, ExtendedContentStyle, ExtendedBackground } from './Extended.css';
import { useQuery } from '@tanstack/react-query';
import { useCallback, useContext, useEffect, useState } from 'react';
import { ExplorerContext } from '../ExplorerContext';
import Solar from './types/Solar';
import DateTimeRangePicker from './DateTimeRangePicker';
import Battery from './types/Battery';
import Energymeter from './types/Energymeter';
import { UilAngleLeftB } from '@iconscout/react-unicons'
import LoadingBar from './LoadingBar';
import Overview from './types/Overview';

const Extended = () => {

    const { loadGateway, closeInfoBar } = useContext(ExplorerContext)
    const { gwId, hex, derType } = useParams();
    const [dateRange, setDateRange] = useState({
        startDate: new Date(new Date().setHours(0, 0, 0, 0)),
        endDate: new Date()
    })

    const setLoadingClassCallback = useCallback((loading) => {
        if(loading){
            document.body.classList.add('loading')
        }else{
            document.body.classList.remove('loading')
        }
    },[]);

    useEffect(() => {
        closeInfoBar()
    },[closeInfoBar])

    const gatewayRequest = useQuery({
        queryKey: ["gw-" + gwId],
        enabled: gwId != null,
        queryFn: async () => {
            var gwResponse = await loadGateway(gwId);

            return gwResponse;
        }
    })

    //todo fix views  
    if(gatewayRequest.isLoading) return <div>Loading...</div>
    if(gatewayRequest.isError) return <div>Error...</div>
    if(!gatewayRequest.isSuccess) <div>Error...</div>

    return (
        <div css={ExtendedWrapperStyle}>
            <div className='inner'>
                <div css={ExtendedHeadingStyle}>
                    <h1>
                        <Link to={`/${hex}/${gwId}`}><UilAngleLeftB /></Link>
                        {gatewayRequest.data.name}
                    </h1>
                    <ul>
                        <li className={derType === "overview" ? "active" : null}>
                            <Link to={`/${hex}/${gwId}/details/overview`}>Overview</Link>
                        </li>
                        <li className={derType === "solar" ? "active" : null}>
                            <Link to={`/${hex}/${gwId}/details/solar`}>Solar</Link>
                        </li>
                        <li className={derType === "energymeter" ? "active" : null}>
                            <Link to={`/${hex}/${gwId}/details/energymeter`}>Energy meter</Link>
                            <span>Coming soon</span>
                        </li>
                        <li className={derType === "battery" ? "active" : null}>
                            <Link to={`/${hex}/${gwId}/details/battery`}>Battery</Link>
                            <span>Coming soon</span>
                        </li>
                    </ul>
                </div>
                <div css={[ExtendedContentStyle, ExtendedBackground]}>
                    {derType !== "overview" && <DateTimeRangePicker 
                        startDate={dateRange.startDate} 
                        endDate={dateRange.endDate} 
                        callback={(dr) => setDateRange(dr)} 
                    />}
                    <div className="data">
                        {derType === "overview" && <Overview gateway={gatewayRequest.data} 
                            isLoading={setLoadingClassCallback}
                        />}
                        
                        {derType === "solar" && <Solar gateway={gatewayRequest.data} 
                            startDate={dateRange.startDate} 
                            endDate={dateRange.endDate} 
                            timeZone={gatewayRequest.data.timeZone}
                            onZoom={(startDate, endDate) => setDateRange({startDate:startDate, endDate:endDate})}
                            isLoading={setLoadingClassCallback}
                        />}

                        {derType === "energymeter" && <Energymeter gateway={gatewayRequest.data} 
                            startDate={dateRange.startDate} 
                            endDate={dateRange.endDate} 
                            timeZone={gatewayRequest.data.timeZone}
                        />}

                        {derType === "battery" && <Battery gateway={gatewayRequest.data} 
                            startDate={dateRange.startDate} 
                            endDate={dateRange.endDate} 
                            timeZone={gatewayRequest.data.timeZone}
                        />}
                    </div>
                    <LoadingBar />
                </div>
            </div>
        </div>
    );
};

export default Extended;