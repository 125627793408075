

const BatteryIcon = (props) => {

    return <svg {...props} width="64" height="64" viewBox="5 5 50 50" xmlns="http://www.w3.org/2000/svg">
        <path d="M42.6663 18.6667H10.6663C7.72082 18.6667 5.33301 21.0545 5.33301 24V40C5.33301 42.9455 7.72082 45.3334 10.6663 45.3334H42.6663C45.6119 45.3334 47.9997 42.9455 47.9997 40V24C47.9997 21.0545 45.6119 18.6667 42.6663 18.6667Z" stroke-width="5.33333" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M54.668 29.3333V34.6666" stroke-width="5.33333" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M16 29.3333V34.6667" stroke-width="5.33333" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M26.668 29.3333V34.6666" stroke-width="5.33333" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M37.332 29.3333V34.6666"  stroke-width="5.33333" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    

}

export default BatteryIcon;


