import { Bar } from "react-chartjs-2"
import { shouldUse24HourFormat } from "../DateHelpers";

const options = (timeZone, startTime) => {
    return {
        responsive: true,
        animation: false,
        cubicInterpolationMode: "monotone",
        plugins: {
            
            legend: {
                display: false,
            },
            title: {
                display: true,
                text: 'Power (kW)',
                color:"#f9f1f1"
            }
        },
        scales: {
            x: {
                type: 'time',
                time: {
                    tooltipFormat: "yyyy-MM-dd HH:mm",
                    displayFormats: { hour: shouldUse24HourFormat(timeZone) ? 'HH:mm' : 'h:mm a' },
                },
                ticks:{
                    color: "#FAF9F6",
                },
                grid: {
                    display: false
                },
                border: {
                    color: "transparent"
                }
            },
            y: {
                ticks:{
                    color: "#FAF9F6",
                    count: 5,
                    format: { maximumFractionDigits: 2, minimumFractionDigits: 0 }
                },
                border: {
                    color: "black"
                }
            }
        }
    }
}

const createGradient = (context, value) => {
    const chart = context.chart;
    const { ctx, chartArea } = chart;
    
    if (!chartArea) return; // If chartArea is not defined, return null
    
    // Create gradient from top to bottom
    const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
    
    if (value < 0) {
        gradient.addColorStop(0, 'rgb(0, 153, 79)');
        gradient.addColorStop(1, 'rgb(0, 255, 132)');
    } else {
        gradient.addColorStop(0, 'rgb(196, 100, 2)');
        gradient.addColorStop(1, 'rgb(255, 155, 54)');
    }
    
    return gradient;
};

const BatteryGraph = ({data, timeZone}) => {
    return (
        <Bar
            options={options(timeZone)} 
            data={{
                datasets: [ {
                    data: data.derData.battery.historical.map(entry => { 
                        return {
                            x: entry.ts, 
                            y: entry.power/1000
                        }
                    }),
                    backgroundColor: function(context) {
                        const value = context.raw?.y;
                        return createGradient(context, value);
                    },
                    borderColor: function(context) {
                        const value = context.raw?.y;
                        return value < 0 
                            ? 'rgb(0, 153, 79)'
                            : 'rgb(255, 99, 132)'    // Red border   // Blue border
                    },
                    borderWidth: 1,
                    label: "kW",
                }]
            }}
        />
    );
}


export default BatteryGraph;