/** @jsxImportSource @emotion/react */
const GridIcon = ({style}) => {

    return <svg width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-utility-pole">
        <path d="M12 2v20"/><path d="M2 5h20"/>
        <path d="M3 3v2"/>
        <path d="M7 3v2"/>
        <path d="M17 3v2"/>
        <path d="M21 3v2"/>
        <path d="m19 5-7 7-7-7"/>
    </svg>
}

export default GridIcon




