/** @jsxImportSource @emotion/react */
import { css, keyframes } from "@emotion/react";

export const OverviewWrapperStyle = css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h2{
        color: white;
        font-size: 30px;
        margin-bottom: 10px;
    }
    p{
        color: white;
    }
`
export const FlowWrapper = css`
    position: relative;
    width: 600px;
    height: 640px;

    .curve{
        z-index: 0;

        &.export-solar-to-grid{
            top: 32%;
            left: 32%;
            transform-origin: center;
            transform: rotate(270deg);
            .particle {animation-direction: reverse}
        }
        &.export-solar-to-house{
            top: 32%;
            left: 52%;
        }
        &.import-export-battery-to-grid{
            bottom: 32%;
            right: 52%;
            transform-origin: center;
            transform: rotate(180deg);
        }
        &.export-battery-to-house{
            bottom: 32%;
            right: 30.5%;
            transform-origin: center;
            transform: rotate(90deg);
            .particle {animation-direction: reverse}
        }
    }

    .line{
        z-index: 0;

        &.import-grid-to-house{
            top: calc(50% - 5px);
            left: 31%;
            width: 38%;
        }
        &.export-solar-to-battery{
            left: calc(50% - 117px);
            top: 49%;
            width: 237px;
            transform: rotate(90deg);
        }
    }
`

export const Circle = css`

    width: 160px;
    height: 160px;
    background-color: rgba(32, 32, 32, 1);
    color: rgba(32, 32, 32, 1);
    border-radius: 50%;
    position: absolute;
    box-shadow: 0 0 20px rgba(22,22,22,0.8);
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5px;
    svg{
        width: 50px;
        height: 50px;
    }
    span{
        font-size: 30px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        position: relative;
        left: -2px;
        svg{
            width: 27px;
            height: 27px;
            position: relative;
            right: -4px;
        }
        &.in{
            font-size: 22px;
            > span{
                align-self: auto;
                font-size: 16px;
            }
        }
        &.out{
            font-size: 22px;
            > span{
                align-self: auto;
                font-size: 16px;
            }
        }
        > span{
            font-size: 20px;
            align-self: flex-end;
        }
    }

    &.inactive{
        opacity: 0.2;
        box-shadow: none;
        span{
            color: rgba(136, 136, 136, 0.5);
            svg{
                fill: rgba(136, 136, 136, 0.5)!important;
                stroke: rgba(136, 136, 136, 0.5)!important;
            }
        }
    }


`;

const moveParticle = keyframes`
    0% {
        stroke-dashoffset: 101;
    }
    100% {
        stroke-dashoffset: 0;
    }
`

export const SolarCircle = css`

    top: 6.5%;
    left: calc(50% - 80px);
    border: 3px solid orange;

    svg{
        fill: orange;
    }

`

export const GridCircle = css`

    top: calc(50% - 80px);
    left: 5%;
    border: 3px solid rgb(88, 85, 122);

    svg{
        stroke: rgb(88, 85, 122);
    }

`

export const HomeCircle = css`

    top: calc(50% - 80px);
    right: 5%;
    border: 3px solid rgb(59, 47, 185);

    svg{
        stroke: rgb(59, 47, 185);
    }

`

export const BatteryCircle = css`

    bottom: 7%;
    left: calc(50% - 80px);
    border: 3px solid purple;
    svg{
        fill: transparent;
        stroke:  purple;
    }

`



export const CurveStyle = css`

    fill: none;
    stroke: rgba(0,0,0,0.35);
    stroke-width: 3;
    position: absolute;
    width: 100px;
    height: 100px;

    .particle {
        fill: none;
        z-index: 2;
        stroke: #3f87c7;
        stroke-width: 1;
        stroke-linecap: round;
        stroke-dasharray: 4, 30;
        stroke-dashoffset: 34;
        
        animation: ${moveParticle} 2s linear infinite;
        
        /* animation-direction: reverse */
    }

`;

export const LineStyle = css`

    fill: none;
    stroke: rgba(0,0,0,0.35);
    stroke-width: 3;
    position: absolute;
    .particle {
        fill: none;
        z-index: 2;
        stroke: #3f87c7;
        stroke-width: 2;
        stroke-linecap: round;
        stroke-dasharray: 4, 30;
        stroke-dashoffset: 34;
        
        animation: ${moveParticle} 2s linear infinite;
        
        /* animation-direction: reverse */
    }

`