



const PvIcon = () => {
    return <svg width="64" height="64" viewBox="0 0 64 64" >
        <g>
        <path d="M15.9151 13.0741C13.435 13.0741 11.3045 14.8224 10.8167 17.2537L6.65336 38.0701C6.01098 41.2901 8.46667 44.2988 11.7518 44.2988H29.397V49.5029H24.1928C22.7536 49.5029 21.5908 50.6657 21.5908 52.1049C21.5908 53.5442 22.7536 54.707 24.1928 54.707H39.8052C41.2444 54.707 42.4072 53.5442 42.4072 52.1049C42.4072 50.6657 41.2444 49.5029 39.8052 49.5029H34.6011V44.2988H52.2463C55.5314 44.2988 57.9952 41.2983 57.3528 38.0701L53.1895 17.2537C52.6935 14.8224 50.5631 13.0741 48.083 13.0741H15.9151ZM27.1933 18.2782H36.8047L37.6504 26.7349H26.3477L27.1933 18.2782ZM22.4283 26.7349H14.2319L15.9151 18.2782H23.2659L22.4283 26.7349ZM13.4431 30.638H22.0299L21.1924 39.0947H11.7518L13.4431 30.638ZM25.9574 30.638H38.0407L38.8863 39.0947H25.1117L25.9574 30.638ZM41.96 30.638H50.5468L52.2463 39.0947H42.8138L41.96 30.638ZM49.7662 26.7349H41.5697L40.724 18.2782H48.0748L50.6281 17.7659L48.083 18.2782L49.7743 26.7349H49.7662Z"/>
        </g>
    </svg>

}

export default PvIcon


