import React, { useEffect, useState, useCallback } from "react";
import { formatISO } from "date-fns";
import { cleanDerSn } from "./Infobar/Ders/DerHelper";

export const ExplorerContext = React.createContext();

const ExplorerContextProvider = ({ children }) => {

  const [hexes, setHexes] = useState([])
  const [infoBarOpen, setInfoBarOpen] = useState(false)
  const [dataBarOpen, setDataBarOpen] = useState(false)
  const [currentHex, setCurrentHex] = useState({hex: null});
  const [mapLoaded, setMapLoaded] = useState(false)
  const [hexGateways, setHexGateways] = useState({})

  useEffect(() => {
    loadHexes((hexData) => setHexes(hexData.data.map.hexes.map(h => h.h3Index)))
  }, []);


  const closeDataBar = useCallback(() => {
    setDataBarOpen(false)
  }, [])
  const openDataBar = useCallback(() => {
    setDataBarOpen(true)
    setInfoBarOpen(false)
  }, [])

  const closeInfoBar = useCallback(() => {
    setInfoBarOpen(false)
    setCurrentHex({hex: null})
  }, [])
  const openInfoBar = useCallback(() => {
    setInfoBarOpen(true)
    setDataBarOpen(false)
  }, [])

  return (
    <ExplorerContext.Provider
      value={{
        hexes: hexes,
        infoBarOpen: infoBarOpen,
        dataBarOpen: dataBarOpen,
        closeInfoBar: closeInfoBar,
        closeDataBar: closeDataBar,
        openInfoBar: openInfoBar,
        openDataBar: openDataBar,
        loadHexGateways: async (hex,callback) =>{ 
          if(hexGateways[hex]) callback(hexGateways[hex])
          else{
            loadHexGateways(hex, (d) => {
              setHexGateways({...hexGateways, [hex]: d})
              callback(d)
            })
          }
        },
        loadGateway: loadGateway,
        loadHistogramProductionData: loadHistogramProductionData,
        loadDailykWhProductionData: loadDailykWhProductionData,
        loadExtendedSolarData: loadExtendedSolarData,
        loadEnergyMeterData: loadEnergyMeterData,
        loadBatteryData: loadBatteryData,
        loadBlocks: loadBlocks,
        loadBlock: loadBlock,
        loadStats: loadStats,
        loadOverViewSolarData: loadOverViewSolarData,
        loadOverViewEnergyMeterData: loadOverViewEnergyMeterData,
        bulkQueryNames: bulkQueryNames,
        currentHex,
        setCurrentHex,
        mapLoaded,
        setMapLoaded,
        
        focusAndFindGw: async (serial) => {
          const gw = await loadGateway(serial)
          setCurrentHex({ index: gw.h3Index, focus: true })
          setInfoBarOpen(true)
        }
      }}
    >
      {children}
    </ExplorerContext.Provider>
  )
}

const executeRequest = async (query) => {
  const response = await fetch(
    // 'http://localhost:5000/',{
    'https://api.srcful.dev/', {
    method: 'post',
    body: query,
    headers: {
      'Content-Type': 'application/json',
      'Content-Length': query.length,
    }
  }
  );
  return response.json();
}

const loadBlocks = async (lastBlockId) => {
  const data = JSON.stringify({
    query: `{
      blocks{
        list${lastBlockId ? `(fromBlock:${lastBlockId})` : ""}{
          block
          endTimestamp
          transactions{
            serial
          }
        }
      }
    }`});
  return await executeRequest(data)
}

const loadStats = async () => {

  const data = JSON.stringify({
    query: `{
      stats {
        dersOnline
        currentKW
        totalPeakPower
      }
    }`});
  return await executeRequest(data)
}

const bulkQueryNames = async (serials) => {

  const data = JSON.stringify({
    query: `{
      gatewayConfiguration{
        ${serials.map(s => `serial_${s}: gatewayName(id:"${s}"){name}`)}
      }
    }`});
  
  const bulkResponse = await executeRequest(data)
  const response = {};
  Object.keys(bulkResponse.data.gatewayConfiguration)
    .forEach(m => response[m.replace("serial_","")] = bulkResponse.data.gatewayConfiguration[m].name)
  return response;
}

const loadOverViewSolarData = async (gwId) => {

  const q = JSON.stringify({
    query: `{
      derData{
        solar(gwId:"${gwId}"){
          latest{
            ts
            power
          }
        }
      }
    }`
  });
  return await executeRequest(q);

}

const loadOverViewEnergyMeterData = async (gwId) => {
  const q = JSON.stringify({
    query: `{
      derData{
        energyMeter(gwId:"${gwId}"){
          latest{
            ts
            consumption
            delivery
          }
        }
      }
    }`
  });
  return await executeRequest(q);

}

const loadBlock = async (blockId) => {
  const data = JSON.stringify({
    query: `{
      blocks{
        block(blockId:${blockId}){
          hashData
          hashPrevBlock
          startTimestamp
          endTimestamp
          transactions{
            serial
            rewardTypes{
              power
              dataPoints
              srcAllocation
            }
          }
          data{
            pop{
              totalProduction
              totalDataPoints
            }
          }
        }
        
      }
    }`});
  return await executeRequest(data)
}

const loadHexes = async (callback) => {

  const data = JSON.stringify({
    query: `{
            map{
             hexes{
               h3Index
             }
           }
        }`});
  callback(await executeRequest(data));
}

const loadHexGateways = async (hex, callback) => {
  const data = JSON.stringify({
    query: `{
            gateway{
              gateways(hex:"${hex}",take:100){
                name
                id
                typeOf
              }
            }
          }`});
  callback(await executeRequest(data));
}

const loadGateway = async (gwId) => {
  const rewardStartDate = formatISO(new Date(new Date() - (30 * 86400000)), { representation: 'date' })
  const query = JSON.stringify({
    query: `{
      gateway{
        gateway(id:"${gwId}"){
          h3Index
          name
          id,
          wallet
          timeZone
          typeOf
          links
          firmwareVersion
          publicKey
          inverterData {
            selectedMake
            model
            sn
            ratedPower
          }
          ders{
            sn
            name
            type
            lastSeen
            validatedBy
            validates
            meta{
              make
              nominalPower
              dataPoints
            }
          }
        }
      }
      token{
        rewards(id:"${gwId}"){
          beta{
            period
            total
            change
            daysOnlineInPeriod
          }
          betaInfo{
            total
          }
          betaDaily{
            sn
            rewards{
              ts
              amount
            }
          }
        }
      }
    }`}
  )
  const data = await executeRequest(query)
  let gwData = data.data.gateway.gateway

  return {...gwData,...data.data.token, ...{rewardStartDate}}
}

const loadExtendedSolarData = async (sns, startDate, stopDate, resolution) => {

  var queries = sns.map((sn, i) => `
    DER${cleanDerSn(sn)}:solar(sn:"${sn}"){
      histogram(start: "${formatISO(startDate)}",stop: "${formatISO(stopDate)}", resolution: "${resolution}"){
        ts
        power
      }
    }
  `);

  const query = JSON.stringify({
      query: `{
        derData{
          ${queries.join("\n")}
        }
      }`
    })

  const data = await executeRequest(query)
  return data.data

}

const loadHistogramProductionData = async (sns, gwId, startDate, resolution, timeZone, includeNullData, validationGwId) => {
  
  const validationQuery = validationGwId ? `derData{
      validationData: solar(sn:"${validationGwId}"){
          histogram(start: "${startDate}",stop: "${formatISO(new Date())}", resolution: "${resolution}"){
            ts
            power
          }
        }
      }
    ` : "";

    var queries = sns.map((sn, i) => {

      return `DER${cleanDerSn(sn)}:solar(sn:"${sn}"){
        histogram(start: "${startDate}",stop: "${formatISO(new Date())}", resolution: "${resolution}", includeNullData: ${includeNullData}){
          ts
          power
        }
      }`

    });


    const query = JSON.stringify({
      query: `{
        derData{
          ${queries.join("\n")}
          solar(gwId:"${gwId}"){
            latest{
              ts
              power
            }
            today(tz:"${timeZone}")
          }
        }
        ${validationQuery}
      }
        `
    });
  
  const data = await executeRequest(query)
  return data.data
}

const loadDailykWhProductionData = async (sns,gwId, startDate, timeZone) => {
  
  //as we want the full kwh for the day regardless of DERS we querie for gwId instead of sns
  var q = `DER${gwId}:solar(gwId:"${gwId}"){
      dailykWh(start: "${startDate}"){
        ts
        power
      }
    }`;

  const query = JSON.stringify({
    query: `{
      derData{
        ${q}
        solar(gwId:"${gwId}"){
          latest{
            ts
            power
          }
          today(tz:"${timeZone}")
        }
      }
    }`});
  const data = await executeRequest(query)
  return data.data

}

const loadEnergyMeterData = async (sn) => {

  const query = JSON.stringify({
    query: `{
      derData{
        energyMeter(sn:"${sn}"){
          powerConsumption{
            ts
            power
            l1
            l2
            l3
          }
          dailyPowerConsumption{
            ts
            power
          }
          latest{
            ts
            consumption
            delivery
          }
        }
      }
    }`
  });
  const data = await executeRequest(query)
  return data.data
}

const loadBatteryData = async (sn, startDate) => {

  const query = JSON.stringify({
    query: `{
      derData{
        battery(sn:"${sn}"){
          latest{
            ts
            soc
            power
          }
          historical(resolution:"2h", start:"${startDate}"){
            ts
            power
            soc
          }
        }
      }
    }`
  });
  const data = await executeRequest(query)
  return data.data

}




export default ExplorerContextProvider