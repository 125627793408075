/** @jsxImportSource @emotion/react */
import { BatteryCircle, Circle, GridCircle, HomeCircle, OverviewWrapperStyle, SolarCircle, CurveStyle, LineStyle, FlowWrapper } from './Overview.css';
import PvIcon from '../../Icons/PvIcon';
import BatteryIcon from '../../Icons/BatteryIcon';
import HomeIcon from '../../Icons/HomeIcon';
import GridIcon from '../../Icons/GridIcon';
import { UilArrowDown, UilArrowUp,UilArrowLeft,UilArrowRight } from '@iconscout/react-unicons'
import { css } from '@emotion/react';
import { useContext, useEffect } from 'react';
import { ExplorerContext } from '../../ExplorerContext';
import { useQueries } from '@tanstack/react-query';

const hasDerType = (ders,type) =>{
    return !!ders.find(der => der.type === type);
}

const wattToKW = (watt) => {
    if(!watt) return 0;
    const kW = watt / 1000;
    
    // Use 1 decimal if over 9, otherwise use 2 decimals
    const decimals = kW >= 10 ? 1 : 2;
    return formatNumber(kW, decimals);
};

const getSystemState = (solar, energymeter) => {

    //isExportingSolarToGrid
    const isExportingSolarToGrid = solar?.derData.solar.latest.power > 0 && energymeter?.derData.energyMeter.latest.delivery > 0;






    const houseKwFromGrid = energymeter?.derData.energyMeter.latest.consumption ?? 0
    const solarKw = wattToKW(solar?.derData.solar.latest.power ?? 0);

    let restSolar = solarKw - (energymeter?.derData.energyMeter.latest.delivery ?? 0);

     if(restSolar < 0){
         restSolar = 0;
    }

    return {
        isExportingSolarToGrid: isExportingSolarToGrid,
        isExportingSolarToHouse: solar?.derData.solar.latest.power > 0 && restSolar !== 0,
        isImportingGridToHouse: energymeter?.derData.energyMeter.latest.consumption > 0,
        houseConsumption: restSolar + houseKwFromGrid,
        

    }

}

const formatNumber = (number, decimals = 2) => {
    if(!number) return 0;
    return Number(number.toFixed(decimals));
}

const Overview = ({gateway, isLoading}) => {

    const { loadOverViewSolarData,loadOverViewEnergyMeterData } = useContext(ExplorerContext)

    const hasSolar = hasDerType(gateway.ders,"Solar");
    const hasEnergyMeter = hasDerType(gateway.ders,"EnergyMeter");
    const results = useQueries({
        queries:[ {
                queryKey: ["Solar"],
                queryFn: () => loadOverViewSolarData(gateway.id),
                enabled: hasSolar,
                refetchInterval: 10000
            }, {
                queryKey: ["EnergyMeter"], 
                queryFn: () => loadOverViewEnergyMeterData(gateway.id),
                enabled: hasEnergyMeter,
                refetchInterval: 10000
            }
        ]
    })

    const resultIsLoading = results.some(
        result => result.isLoading && result.fetchStatus !== 'idle'
    );
    const isRefetching = results.some(
        result => result.isFetching && result.fetchStatus !== 'idle'
    );

    useEffect(() => {
        isLoading(resultIsLoading || isRefetching)
        return isLoading(false)
    },[resultIsLoading,isRefetching, isLoading]);

    const solarData = results[0].isSuccess ? results[0].data.data : null;
    const energyMeter = results[1].isSuccess ? results[1].data.data : null;

    var state = !resultIsLoading ? getSystemState(solarData,energyMeter) : {};	

    return (
            <div css={OverviewWrapperStyle}>
                <h2>Energy overview (beta)</h2>
                <p>Battery support coming soon</p>
                <div css={FlowWrapper}>
                    <div css={[Circle,SolarCircle]} className={hasSolar && !resultIsLoading ? null : "inactive"}>
                        <PvIcon />
                        <span>{hasSolar && !resultIsLoading ? `${wattToKW(solarData.derData.solar.latest.power)}` : "-"}<span>kW</span></span>
                    </div>
                    <Curve className={"export-solar-to-grid"} animate={state.isExportingSolarToGrid ?? false} />
                    <Curve className={"export-solar-to-house"}  animate={state.isExportingSolarToHouse ?? false} />
                    <Line className={"export-solar-to-battery"} />
                    <div css={[Circle, GridCircle]} className={hasDerType(gateway.ders,"EnergyMeter") ? null : "inactive"}>
                        <GridIcon />
                        <span className='in'><UilArrowLeft css={ArrowColorStyle("lightblue")} /> {hasEnergyMeter && !resultIsLoading ? formatNumber(energyMeter.derData.energyMeter.latest.delivery) : "-"} <span>kW</span></span>
                        <span className='out'><UilArrowRight css={ArrowColorStyle("#e69373")} /> {hasEnergyMeter && !resultIsLoading ? formatNumber(energyMeter.derData.energyMeter.latest.consumption) : "-"} <span>kW</span></span>
                    </div>
                    <Line className={"import-grid-to-house"} animate={state.isImportingGridToHouse} />
                    <div css={[Circle, HomeCircle]}>
                        <HomeIcon />
                        <span>{hasEnergyMeter && !resultIsLoading ? formatNumber(state.houseConsumption) : "?"} <span>kW</span></span>
                    </div>
                    <div css={[Circle, BatteryCircle]} className={"inactive"}>
                        <BatteryIcon css={css`width: 70px!important;`} />
                        <span className='in'><UilArrowDown css={ArrowColorStyle("lightblue")} />0 <span>kW</span></span>
                        <span className='out'><UilArrowUp css={ArrowColorStyle("#e69373")} />0 <span>kW</span></span>
                    </div>
                    <Curve className={"import-export-battery-to-grid"}  />
                    <Curve className={"export-battery-to-house"}  />
                </div>
            </div>
        
    );
};

export default Overview;

const ArrowColorStyle = (color) => css`
    stroke: ${color} !important;
    fill: ${color} !important;
`

const Curve = ({className, animate}) => {

    

    return (
        <svg className={`curve ${className} animate-particle`} css={CurveStyle} viewBox="0 0 50 50">
            <path d="M1 1 Q 1 49 49 49"/>
            { animate && <path class="particle" d="M1 1 Q 1 49 49 49"/> }
        </svg>
    );

}

const Line = ({className, animate}) => {

    return (
        <svg className={`line ${className}`} css={LineStyle} viewBox="15 45 170 10">
            <line x1="20" y1="50" x2="180" y2="50"/>
            { animate && <line class="particle" x1="20" y1="50" x2="180" y2="50"/>}
        </svg>
    );

}