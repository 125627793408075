/** @jsxImportSource @emotion/react */
import { useContext, createRef } from "react"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import { ExplorerContext } from "../ExplorerContext"
import { ContentFooterLinks, NoDerInfoStyle } from "./InfobarContent.css"
import { UilAngleLeftB, UilTimes } from '@iconscout/react-unicons'
import { InfoSectionHeadingStyle, InfoSectionStyle, InfoSectionHeadingStyleBack, SoftwareMinerStyle, DefaultBackground, NorthernLightsBackground, InfoSectionHeadingStyleClose } from "../BarStyle.css"
import { useQuery } from "@tanstack/react-query"
import { useEffect } from "react"
import { Tooltip } from "react-tooltip"
import { Code } from "../Footer/Footer"
import VerifiedIcon from "../Icons/VerifiedIcon"
import CopyToClipboard from "./CopyToClipboard"
import { useIsVisible } from "../Hooks/useIsVisible"
import SolarProduction from "./Ders/SolarProduction"
import EnergyMeter from "./Ders/EnergyMeter"
import Expandable from "./Expandable"
import BetaRewards from "./BetaRewards"
import Battery from "./Ders/Battery"


const isHardware = (gateway) => {
    return gateway?.typeOf === "Hardware";
}

export const truncateString = (str) => {
    if(str === null || str === undefined) return "";
    if (str.length <= 8) return str;
    return str.slice(0, 4) + '...' + str.slice(-4);
}

const isValidated = (gateway) => {
    return gateway?.ders?.some(f => (f.validatedBy?.length ?? 0) > 0);
}

export const InfobarContent = () => {
    
    const { gwId, hex } = useParams();
    const scrollRef = createRef();
    const { mapLoaded, loadGateway, closeInfoBar, setCurrentHex, loadHexGateways } = useContext(ExplorerContext)
    const location = useLocation();
    const navigate = useNavigate()
    const gwSerial = gwId
    useIsVisible(scrollRef,(intersecting) => {
        if(intersecting && !gwId && hex){
            loadHexGateways(hex, (d) => {
                navigate(`/${hex}/${d.data.gateway.gateways[0].id}`);
            })
        }
    });
    
    

    const gatewayRequest = useQuery({
        queryKey: ["gw-" + gwSerial,],
        enabled: gwSerial != null,
        queryFn: async () => {
            var gwResponse = await loadGateway(gwSerial);

            return gwResponse;
        }
    })

    const onClose = (e) => {
        e.preventDefault()
        closeInfoBar(false)
        setCurrentHex({hex: null, egw: null})
        navigate("/")
      
    }

    //zoom to selected hex
    useEffect(() => {
        setTimeout(() => {
            if (gatewayRequest.data && gatewayRequest.data.h3Index) {            
                setCurrentHex({
                    index: gatewayRequest.data.h3Index,
                    focus: true
                })
            }
        },0)
    },[mapLoaded]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (scrollRef.current && gwId) {
            try{
            scrollRef.current.scrollIntoView({behavior: "instant", block: "center", inline: "center"})
            }
            catch(e){
                alert("Error: " + e)
            }
        }
    },[gwId]); // eslint-disable-line react-hooks/exhaustive-deps

    const skeletonMargin = (bottom) => { return {"marginBottom":bottom}}

    const background = gatewayRequest.isSuccess && isValidated(gatewayRequest.data) ? NorthernLightsBackground : DefaultBackground;

    const derTypes = gatewayRequest.isSuccess && gatewayRequest.data?.ders && 
        gatewayRequest.data.ders.reduce((acc, der) => {
            if (!acc[der.type]) {
            acc[der.type] = [];
            }
            acc[der.type].push(der);
            return acc;
        }, {});

    return <SkeletonTheme baseColor="#202939" highlightColor="rgba(106, 134, 186,0.2)">
        <div ref={scrollRef} className="innerScroll" id="gw">
            <div>
                <div css={[InfoSectionStyle, InfoSectionHeadingStyle, background]}>   
                    {!location?.state?.passThrue && gatewayRequest.data && <Link css={InfoSectionHeadingStyleBack} to={`/${gatewayRequest.data.h3Index}`}><UilAngleLeftB /></Link>}
                    <Tooltip id="verified"  style={{ zIndex: 99 }} place={"top-start"} zIndex={100} noArrow content={<span>Verifed gateway, has multiple <br/>sources of data-validation</span>} />             
                    <Tooltip id="softwareminer" style={{ zIndex: 99 }} place={"top-start"} noArrow content={<span>This is a cloud gateway, <br/>the quality of data is lower.</span>} />
                    <h2>{gatewayRequest.data ? 
                        <>{gatewayRequest.data.name} 
                        {isValidated(gatewayRequest.data) ? 
                            <VerifiedIcon data-tooltip-id="verified"/> : 
                            <>{gatewayRequest.isSuccess && !isHardware(gatewayRequest.data) && <Code data-tooltip-id="softwareminer" css={SoftwareMinerStyle}/>}</>
                        }
                        </>
                        ?? "Unknown Gateway 😭" 
                        : 
                        <Skeleton count={1} style={skeletonMargin("0px")} width="60%" />}
                    </h2>
                    <span className="id">{gatewayRequest.data ? 
                        <><span>{gatewayRequest?.data?.id}</span><CopyToClipboard text={gatewayRequest?.data?.id} /></> ?? "" : 
                        <Skeleton style={skeletonMargin("0px")} count={1} width="100px" />}
                    </span>
                    <a href="/" onClick={onClose} css={InfoSectionHeadingStyleClose}><UilTimes /></a>
                    <Expandable>
                        <h3>Gateway metadata</h3>
                        <ul css={[ContentFooterLinks]}>
                            {isHardware(gatewayRequest.data) && <li>
                                Public key:<span className="devicepk">{truncateString(gatewayRequest?.data?.publicKey)}</span><CopyToClipboard text={gatewayRequest?.data?.publicKey} copiedClass={"deviceCopied"} />
                            </li>}
                            <li>
                                Wallet:<span className="wallet">{truncateString(gatewayRequest?.data?.wallet)}</span><CopyToClipboard text={gatewayRequest?.data?.wallet} copiedClass={"walletCopied"} />
                            </li>
                            {isHardware(gatewayRequest.data) && <li>
                                Version: <span>{gatewayRequest?.data?.firmwareVersion}</span>
                            </li>}
                            { 
                            gatewayRequest?.data?.ders != null &&
                                <li>
                                    Number of DERs: <span title={gatewayRequest?.data?.ders.map(s => s.name)}>{gatewayRequest?.data?.ders?.length ?? 0}</span>
                                </li>        
                            }
                        </ul>
                    </Expandable>
                </div>
                {
                    derTypes?.Solar && <SolarProduction 
                        gatewayRequest={gatewayRequest} 
                        ders={derTypes?.Solar} 
                        background={background} 
                    />
                }
                {
                    derTypes?.EnergyMeter && <EnergyMeter 
                        gatewayRequest={gatewayRequest} 
                        ders={derTypes?.EnergyMeter}
                        background={background} 
                    />
                }
                {
                    derTypes?.Battery && <Battery 
                        gatewayRequest={gatewayRequest} 
                        ders={derTypes?.Battery}
                        background={background} 
                    />
                }



                {
                    gatewayRequest.isSuccess && gatewayRequest.data?.ders?.length === 0 && <div css={[InfoSectionStyle, background]}>

                        <h3>Gateway has no DERs</h3>
                        <br />
                        <p css={NoDerInfoStyle}>No Decentralized Energy Resources(DERs) has been found yet. 
                            <span>DERs will be automaticly found and registered once data is sent to the Sourceful systems.</span>
                        </p>
                    </div>
                }
                <BetaRewards gatewayRequest={gatewayRequest} background={background} />


        </div>
    </div>
    </SkeletonTheme>

}
